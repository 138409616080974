<template>
  <section class="checkout-page d-flex flex-row justify-between ">
    <div class="btn-back mobile d-flex" @click="back()">
      <img src="@/assets/icons/Arrow-left.svg" alt="">
      <h6 class="title">Back</h6>
    </div>
    <div class="information">
      <div class="btn-back desktop d-flex" @click="back()">
        <img src="@/assets/icons/Arrow-left.svg" alt="">
        <h6 class="title">Back</h6>
      </div>
      <div class="payment-steps d-flex flex-row align-center">
        <div class="step d-flex flex-row align-center">
          <div class="number passed d-flex flex-row align-center justify-center"><img src="@/assets/icons/ok.svg" alt=""></div>
          <h5 class="title passed">Delivery Info</h5>
        </div>
        <div class="line passed"></div>
        <div class="step d-flex flex-row align-center">
          <div class="number passed d-flex flex-row align-center justify-center"><img src="@/assets/icons/ok.svg" alt=""></div>
          <h5 class="title passed">Personal Info</h5>
        </div>
        <div class="line passed"></div>
        <div class="step d-flex flex-row align-center">
          <div class="number d-flex flex-row align-center justify-center">3</div>
          <h5 class="title">Payment</h5>
        </div>
      </div>
      <div class="tile-payment">
        <div class="payment-description">
          <h4>Select payment method</h4>
          <h6 class="description">All transactions are secure and encrypted</h6>
        </div>
        <ul class="payment-list d-flex flex-row ">
          <li class="payment-list__item d-flex flex-row align-center">
            <div class="custom-checkbox-container">
              <input class="custom-checkbox" v-model="paymentType" type="radio" :value="0">
              <label class="custom-label">
                <img src="@/assets/img/Checkout/ideal.png" alt="">
              </label>
            </div>
          </li>
          <li class="payment-list__item d-flex flex-row align-center">
            <div class="custom-checkbox-container">
              <input class="custom-checkbox" v-model="paymentType" type="radio" :value="1">
              <label class="custom-label">
                <img src="@/assets/img/Checkout/visa-mastercard-icon.png" alt="">
              </label>
            </div>
          </li>
          <li class="payment-list__item bottom d-flex flex-row align-center">
            <div class="custom-checkbox-container">
              <input class="custom-checkbox" v-model="paymentType" type="radio" :value="2">
              <label class="custom-label">
                <img src="@/assets/img/Checkout/g-a-pay.svg" alt="">
              </label>
            </div>
          </li>
        </ul>
      </div>
      <form action="">
        <h4 class="title">Select your bank</h4>
        <div >
          <div class="select-bank d-flex flex-row justify-between align-center">Bank name <img src="@/assets/icons/Arrow-right.svg" alt=""></div>
          <ul></ul>
        </div>
        <div class="select-conditions">
          <div class="custom-checkbox-container">
            <input  class="custom-checkbox" type="checkbox">
            <label class="custom-label"></label>
          </div>
          <p>I agree to the <router-link to="/terms-conditions">Terms and Conditions</router-link> and the <router-link to="/terms-conditions">Refund and Cancelation policy</router-link></p>
        </div>
        <div class="next-step d-flex flex-row justify-between align-center">
          <div class="btn-continue-shop">
            Continue shopping
          </div>
          <div class="btn-next-step" @click="nextPage">
            <h6>Purchase € 112.86</h6>
          </div>
        </div>
      </form>
    </div>
    <CheckoutBasket />
  </section>
</template>

<script>

export default {
  name: "CheckoutPersonalInfo",
  data(){
    return{
      value:null,
      paymentType:0,
    }
  },
  validations:{

  },
  computed:{

  },
  methods:{
    nextPage(){
      console.log(1)
      this.$v.$touch()
      if (this.$v.$invalid) {
        this.submitStatus = 'ERROR'
      } else {
        this.$store.dispatch('setPersonalInfo',this.personalInfo)
      }
    },
    goToShop(){
      this.$router.push('/')
    },
    back(){
      this.$router.push('/checkout')
    }
  },
  components:{
    CheckoutBasket:()=> import('../CheckoutBasket/CheckoutBasket'),
  },
  mounted() {
  }
}
</script>

<style scoped lang="scss">
@import "CheckoutPayment";
</style>